.expertise-wrapper{
    padding: 20px;
   // border: 1px solid red;
   padding-top: 40px;
    height: fit-content;
    overflow: hidden;

    
h2{
    text-align: center;
    margin-top: 50px;
    text-transform: uppercase;
    span{
        &::after{
            width: 220px !important;
            height: 220px;
            top: -80px;
            right: -17px;
        }
    }
    
}
.card-container{
    //border: 1px solid red;
    position: relative;
    width:100%;
    height:auto;
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 0 auto;
    //background-image: url("../../../../public/images/home/expertiseBg.png");

    &::before{
        position: absolute;
        content: url("../../../../public/images/home/expertiseBg.png");
        top: 0;
        right: 200px;
        width: 100%;
    }

    .lineLeft{
        position: absolute;
        top: 100px;
        left: 33%;
    }
    .lineRight{
        position: absolute;
        top:100px;
        right: 33%;
    }
    .lineHori{
        position: absolute;
        top:55%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
}
@media screen and (max-width: 1800px){
    .expertise-wrapper .card-container::before {
       display: none;
    }
}
@media screen and (max-width: 1600px){
    .expertise-wrapper .card-container {
        
        width: 100%;
       
    }
}

@media screen and (max-width: 1530px){
    
    .lineHori{
        display: none;
    }
    .lineLeft{
        display: none;
    }
    .lineRight{
        display: none;
    }
}
@media screen and (max-width: 1440px){
    .expertise-wrapper{
        h2{
            font-size: 40px;
            span{
                &::after{
                    width: 100px !important;
                    height: 100px !important;
                    top: -30px;
                    right: -10px;
                }
            }
        }
    }

}
@media screen and (max-width: 1330px){
    .expertise-wrapper .card-container {
        grid-template-columns: repeat(2, 1fr);
    }  

}

@media screen and (max-width: 600px){
    .expertise-wrapper .card-container {
        grid-template-columns: repeat(1, 1fr);
    }  
    .expertise-wrapper h2 span::after {
        width: 98px !important;
        height: 100px;
    }

    .expertise-wrapper {
        h2 {
          span {
            color: #2B2B2B;
            &:after {
              display: none;
            }
          }
        }
      }
}