.about-wrapper {
  // width: 100%;
  // height: 100vh;

  background-image: url("../../../assets/images/about-text-bg.png");
  background-size: contain;
  background-position: 100% 40%;
  background-repeat: no-repeat;
  //border: 1px solid red;
  padding: 100px 20px 0px 20px;
//overflow: hidden;
  h2 {
    text-align: center;
    span {
      z-index: 333;
    }
  }

  .about-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 80px;
    //border: 1px solid purple;
    margin-top: 120px;
    margin-bottom: 60px;

    .content-left {
      width: 50%;
      height: 500px;
      //background-color: blanchedalmond;

      h3 {
        background-image: linear-gradient(
          90deg,
          #0072ff 0%,
          #00c6ff 100%
        ) !important;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      p {
        max-width: 728px;
        width: 100%;
        color: #707070;
        font-family: Inter;
        font-size: 22.823px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // text-transform: capitalize;
        text-align: left;
        margin-bottom: 60px;
        margin-top: 25px;
      }
      button {
        &:hover {
          border-radius: 6px;
          background-image: linear-gradient(90deg, #0072ff 0%, #00c6ff 100%);
          cursor: pointer;
        }
      }
    }

    .content-right {
      width: 50%;
      height: 750px;
      //background-color: rgb(153, 196, 37);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 800.436px;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 1720px) {
  .about-wrapper {
    .about-content {
      padding: 0 60px;
      .content-right {
        padding: 10px;
        height: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .about-wrapper {
    h2 {
      font-size: 40px !important;
      .rightSpan_circle {
        &::after {
          width: 100px !important;
          height: 100px;
          top: -30px;
          left: -10px;
        }
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .about-wrapper .about-content .content-left p {
    font-size: 20.823px;
  }
}

@media screen and (max-width: 600px) {
  .about-wrapper {
    padding: 50px 20px 0px 20px;
    .about-content {
      flex-direction: column;
      margin-top: 30px;
      //border: 1px solid red;
      padding: 0;
      .content-left {
        width: 100%;
        height: auto;
        p {
          margin-bottom: 20px;
          font-size: 18px !important;
        }
      }
      .content-right {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
  // .about-wrapper h2 .rightSpan_circle::after {
  //   width: 100px !important;
  //   height: 100px;
  //   top: -29px;
  //   left: -10px;
  // }

  .about-wrapper {
    h2 {
      .rightSpan_circle {
        color: #2B2B2B;
        &:after {
          display: none;
        }
      }
    }
  }

  .about-wrapper .about-content .content-left p {
    font-size: 19.823px;
}
}
