.slick-arrow.slick-prev {
  position: absolute;
  background-color: #018eff !important;
  border: 2px solid #018eff;
  overflow: hidden;
  top: unset;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  bottom: -150px;
  left: 44.5%;
  font-size: 20px !important;
  z-index: 3;
  &::before {
    content: url("../../../../public/images/home/cheveron-blue.png");
    position: absolute;
    top: 5px;
    left: 5px;
    color: var(--white);
    opacity: 1;
    transform: rotate(360deg);
    filter: brightness(0) invert(1);
  }
}
.slick-arrow.slick-prev.slick-disabled {
  position: absolute;
  background-color: #fff !important;
  border: 2px solid #018eff;
  overflow: hidden;
  top: unset;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  bottom: -150px;
  left: 44.5%;
  font-size: 20px !important;
  z-index: 3;
  &::before {
    content: url("../../../../public/images/home/cheveron-blue.png");
    position: absolute;
    top: 5px;
    left: 5px;
    color: var(--white);
    opacity: 1;
 
    transform: rotate(360deg);
    filter: brightness(1) invert(0);
  }
}
.slick-arrow.slick-next {
  position: absolute;
  background-color: #018eff !important;
  top: unset;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  bottom: -150px;
  right: 45.5%;
  font-size: 20px !important;
  z-index: 3;
  &::before {
    content: url("../../../../public/images/home/icons8-arrow-right-48.png");
    position: absolute;
    top: 5px;
    left: 7px;
    color: var(--white);
    opacity: 1;
  }
}
.slick-arrow.slick-next.slick-disabled {
  position: absolute;
  background-color: #fff !important;
  border: 2px solid #018eff;
  top: unset;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  bottom: -150px;
  right: 45.5%;
  font-size: 20px !important;
  z-index: 3;
  &::before {
    content: url("../../../../public/images/home/cheveron-blue.png");
    position: absolute;
    top: 3px;
    left: 3px;
    color: var(--white);
    opacity: 1;
    transform: rotate(180deg);
    
  }
}
.slick-slide {
  width: 500px;
}

@media screen and (max-width: 1879px) {

  .slick-arrow.slick-prev.slick-disabled {
    left: 41.5%;
}
  .slick-arrow.slick-prev {
    left: 41.5%;
    font-size: 20px !important;
  }

  .slick-arrow.slick-next.slick-disabled {
    right: 47.5%;
}
  .slick-arrow.slick-next {
    right: 47.5%;
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1500px) {

  .slick-arrow.slick-prev.slick-disabled {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    bottom: -113px;
    left: 42.5%;
    &::before{
      top: 0;
      left: 0;
    }
   
}

.slick-arrow.slick-next.slick-disabled {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  bottom: -114px;
  right: 45.5%;
 &::before{
  top: 0;
  left: -1px;
 }
}
  .slick-arrow.slick-prev {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    bottom: -113px;
    left: 42.5%;
    &::before {
      top: 1px;
      left: 1px;
    }
  }
  .slick-arrow.slick-next {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    bottom: -113px;
    right: 44.5%;

    &::before {
      top: 1px;
      left: 3px;
    }
  }
}

@media screen and (max-width: 600px) {

  .slick-arrow.slick-prev.slick-disabled {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    bottom: -71px;
    left: 35.5%;

    &::before{
      top: -5px;
      left: -5px;
    }
}

.slick-arrow.slick-next.slick-disabled {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  bottom: -70px;
  right: 30.5%;

  &::before{
    top: -6px;
    left: -5px;
  }
}
  .slick-arrow.slick-next {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    bottom: -70px;
    right: 30.5%;

    &::before {
      top: -2px;
      left: -3px;
      font-size: 15px;
    }
  }

  .slick-arrow.slick-prev {
    // border: 1px solid green;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    bottom: -70px;
    left: 35.5%;

    &::before {
      top: -5px;
      left: -4px;
      font-size: 15px;
    }
  }
}
