%card-para-style{
    color: var(--UI-dark, #222);
    font-family: "Inter";
    font-size: 22px;
    text-align: start;
    font-weight: 400;
    line-height: 33.016px; 
}

%profile-left-container{
    width: 80px;
    height: 80px;
    background: #018EFF;
    border-radius: 50px;
    overflow: hidden;
}
%profile-right-container-h2{
    color: var(--UI-dark, #222);
    font-family: "Inter";
    font-size: 22px;
    font-weight: 600;
    line-height: 33.016px; 
    margin-bottom: 0;
}
%profile-right-container-p{
    color: #555;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 33.016px;
    padding-top: 0;
    margin-bottom: 0;
}
.blogsContent{
    position: relative;
    width: 80%;
    margin: auto;

    .blogs-top-container{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        gap: 3%;

        .blog-top-leftcontainer{
            width: 50%;
            position: relative;
            img{
                width: 100%;
            }
            .hover-back-img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: none;
                transition: 0.3s ease-in-out;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 8.8%;
                }
            }

            .hover-img{
                position: absolute;
                width: 80px;
                height: 80px;
                left: 46%;
                transform: translateY(0);
                transition: transform 0.4s ease-in-out;
    
    
                
                img{
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
    
                }
            }
            // .hover-background{
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 100%;
            //     // display: none;
            //     transition: 0.3s ease-in-out;

            //     img{
            //         width: 100%;
            //         height: 100%;
            //         border-radius: 8.8%;
            //     }
            // }
          
        }
        .blog-top-rightcontainer{
            width: 50%;

            h2{
                color: var(--UI-dark, #222);
                font-family: "Inter";
                font-size: 44.021px;
                font-weight: 700;
                line-height: 49.524px;
            }
            p{
                @extend %card-para-style;
                padding-top: 33px;
            }

            .profile-section{
                display: flex;
                align-items: center;
                gap: 3%;
                .profile-left-container{
                    @extend %profile-left-container;
                    img{
                        width: 100%;
                    }

                    

                }
                .profile-right-container{
                    h2{
                        @extend %profile-right-container-h2;
                    }
                    p{
                        @extend %profile-right-container-p;
                    }
                }
                
            }
        }

        &:hover{
            .blog-top-leftcontainer{
                .hover-img{
                    transform: translateY(-270px);
                    img{
                        opacity: 1;
                        transition: opacity 0.4s ease-in-out;
                    }
                }
                .hover-back-img{
                    display: block;
                    transition: transform 0.3s ease-in-out;
                }

            }
        }

    }


    
    .cards-containermain{
        padding: 194px 0 220px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 4% 2%;

    }
}




// blogs card css

.blogs-card-container{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .blog-card-topcontainer{
        border-radius: 27px;
        position: relative;
        overflow: hidden;
        img{
            width: 100%;
        }
        .hover-img{
            position: absolute;
            width: 80px;
            height: 80px;
            left: 44%;
            transform: translateY(0);
            transition: 0.3s ease-in-out;


            
            img{
                opacity: 0;
                transition: opacity 0.3s ease-in-out;

            }
        }
          .hover-background{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: none;
                transition: 0.3s ease-in-out;

                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 8.8%;
                }
            }
        

    }
    .blog-card-bottomcontainer{
        padding-top: 15px;

        h2{
            color: var(--UI-dark, #222);
            font-family: "Inter";
            font-size: 38px;
            font-style: normal;
            font-weight: 700;
            line-height: 49.524px; /* 130.326% */
        }
        p{
            @extend %card-para-style; 
        }
        .card-profile{
            display: flex;
            gap: 3%;
            align-items: center;
            .card-profile-left{
                @extend %profile-left-container;

            }
            .card-profile-right{

                h2{
                    @extend %profile-right-container-h2;
                }
                p{
                    @extend %profile-right-container-p;
                }
            }
            img{
                width: 100%;
            }
        }

    }

    &:hover{
        .blog-card-topcontainer{
            .hover-img{
                transform: translateY(-170px);
                img{
                    opacity: 1;
                    transition: opacity 0.3s ease-in-out;
                }
            }
            .hover-background{
                display: block;
                img{
                    transition: opacity 0.3s ease-in-out;

                }
            }
        }
    }
}

.blogsbackground{
    // display: none;
    position: absolute;
    z-index: -10;
    left: 0;
    bottom: 0;
    top: 0;
    img{
            width: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            
    }
}


@media screen and (max-width:1700px) {
    .blogsContent{
        width: 90%;

        .cards-containermain{
            padding: 120px 0;
        }
        .blogs-top-container
        {
            .blog-top-rightcontainer {
                h2{
                    font-size: 38px;
                }
                p{
                    font-size: 20px;
                }
            }
        }
        
    }

    .blogs-card-container{
        .blog-card-bottomcontainer{
            h2{
                font-size: 35px;
            }
            p{
                font-size: 20px;
            }
        }
    }
}
@media screen and (max-width:1500px) {
    .blogsContent{
        width: 92%;

        .blogs-top-container
        {
            .blog-top-rightcontainer {
                h2{
                    font-size: 30px;
                    line-height: 38px;
                }
                p{
                    font-size: 18px;
                    line-height: 27px;
                    padding-top: 16px;
                }
                .profile-section{
                    .profile-left-container{
                        width: 65px;
                        height: 65px;
                    }
                }
            }
            &:hover{
                .blog-top-leftcontainer{
                    .hover-img{
                        transform: translateY(-200px);
                       
                       
                    }
                }
            }
        }
    }

    .blogs-card-container{

        .blog-card-topcontainer{
            .hover-img{
                width: 65px;
                height: 65px;
            }
        }
        .blog-card-bottomcontainer{
            h2{
                font-size: 28px;
            }
            p{
                font-size: 17px;
                line-height: 25px;
            }
            .card-profile{
                .card-profile-left{
                    width: 65px;
                    height: 65px;
                }
                .card-profile-right{
                    h2{
                        font-size: 18px;
                        line-height: normal;
                    }
                    p{
                        font-size: 14px;
                        line-height: normal;
                        padding-top: 4px;
                    }
                }
            }
            
        }
        &:hover{
            .blog-card-topcontainer{
                .hover-img{
                    transform: translateY(-130px);
                }
            }
        }
        
    }
}
@media screen and (max-width:1200px) {
    .blogsContent{
        width: 95%;

        .blogs-top-container
        {
            .blog-top-rightcontainer {
                h2{
                    font-size: 25px;
                    line-height: 30px;
                }
                p{
                    font-size: 15px;
                    line-height: 22px;
                    padding-top: 10px;
                }
            }
        }
    }

    .blogs-card-container{
        .blog-card-bottomcontainer{
            h2{
                font-size: 25px;
                line-height: normal;
            }
            p{
                font-size: 15px;
                line-height: 22px;
            }
        }
        &:hover{
            .blog-card-topcontainer{
                .hover-img{
                    transform: translateY(-130px);
                }
            }
        }
    }
}

@media screen and (max-width:600px) {
    .blogsContent{
        width: 90%;
        .blogs-top-container{
            flex-direction: column;
            margin-top: 50px;
            
            .blog-top-leftcontainer{
                width: 100%;

                .hover-img{
                    width: 50px;
                    height: 50px;
                }
            }
            .blog-top-rightcontainer{
                width: 100%;
                margin-top: 30px;
                h2{
                    text-align: start;
                    font-size: 20px !important;
                    line-height: normal;
                }
                p{
                    font-size: 15px;
                }
                .profile-section{
                    .profile-left-container{
                        width: 50px;
                        height: 50px;
                    }
                    .profile-right-container{
                        h2{
                            font-size: 16px !important;
                            line-height: unset;
                        }
                        p{
                            font-size: 11px !important;
                            line-height: unset;
                        }
                    }
                }
            }
            &:hover{
                .blog-top-leftcontainer{
                    .hover-img{
                        transform: translateY(-120px);
                       
                    }
                }
            }
        }
        .cards-containermain{
            grid-template-columns: repeat(1, 1fr);
            gap: 23px;
            padding: 35px 0;
        }
    } 
    .blogsbackground{
       
        img{
            display: none;
        }
    }

    .blogs-card-container{
        .blog-card-bottomcontainer{
            h2{
                text-align: start;
                font-size: 23px !important;
                line-height: normal;
            }
            p{
                line-height: 21px;
            }
            .card-profile{
                .card-profile-left{
                    width: 50px;
                    height: 50px;
                }
                .card-profile-right{
                    h2{
                        font-size: 16px !important;
                        line-height: normal;
                    }
                    p{
                        font-size: 11px;
                        line-height: normal;
                    }
                }
            }
        }
        .blog-card-topcontainer{
            border-radius: 10px;
            .hover-img{
                width: 50px;
                height: 50px;
            }
        }
    }

    
}