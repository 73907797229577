.pagenotfound{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding:  150px 200px;
    height: 100vh;
    background-image: url("../../assets/images/pnfbackpic.png") ;
    background-size: cover ;
    gap: 10%;
    .pageleftcontainer{
        width: 50%;
        img{
            width: 100%;
        }
    }
    .pagerightcontainer{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1{
            color: #0184FF;
            text-align: center;
            font-family: "Arial";
            font-size: 365.883px;
            font-weight: 700;
        }
        h4{
            color: #2B2B2B;
            font-family: "Inter";
            font-size: 85.775px;
            font-weight: 500;
            padding-bottom: 50px;
        }
        button{
            border-radius: 6px;
            background: var(--Button, linear-gradient(90deg, #00C6FF 0%, #0072FF 100%));
            width: 190px;
            height: 55px;
            border: none;
            color: #FFF;
            font-family: "Inter";
            font-size: 20px;
            font-weight: 500;
        }

    }
}

@media screen and (max-width:1800px) {
    .pagenotfound{
        .pagerightcontainer{
            h1{
                font-size: 300px;
            }
            h4{
                font-size: 65px;
            }
        }
    }
}

@media screen and (max-width:1500px) {
    .pagenotfound{
        padding: 100px 120px;
        gap: 0;
        .pagerightcontainer{
            h1{
            font-size: 230px;
            }
            h4{
                font-size: 50px;
            }
        }
    }
}

@media screen and (max-width:600px) {
    .pagenotfound{
        padding: 0;
        .pageleftcontainer{
            display: none;
        }
        .pagerightcontainer{
            width: 100%;
            justify-content: center;
            align-items: center;
            h1{
            font-size: 160px;

            }
            h4{
                font-size: 37px;
            }
            button{
                width: 120px;
                height: 40px;
                font-size: 15px;
            }
        }
    }
}