.portBanner {
 height: 700px;
  //border: 1px solid red;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  h1 {
    color: white;
    text-align: center;
    position: relative;
    z-index: 3;
  }

  .hero-vid {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 700px;
    z-index: 2;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width:600px){

    .portBanner{
        height: 400px;
        .hero-vid{
            height: 400px;
        }
    }
}