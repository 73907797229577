.contactBanner{
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url("../../../../public/images/contact/contactBg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: black;
    overflow: hidden;
    color: white !important;
}

@media screen and (max-width:600px) {
    .contactBanner{
        height: 400px;
   
    }
    
    
}