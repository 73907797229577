body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  font-family: 'Poppins', sans-serif;
}
h1 {
  font-size: 74px;
  font-weight: 700;
}
h2 {
  font-size: 60px;
  font-weight: 700;
  text-align: center ;
  text-transform: uppercase;
}
h3 {
  font-size: 48px;
  font-weight: 700;
}
h4 {
  font-size: 38px;
  font-weight: 700;
}
h5 {
  font-size: 28px;
  font-weight: 700;
}
h6 {
  font-size: 25px;
  font-weight: 400;
}

p {
  color: #fff;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

 
}
p.body-small {
  font-size: 18px;
}

.rightSpan_circle {
  position: relative;
  color: #fff;
  &::after {
    position: absolute;
    content: "";
    top: -47px;
    right: -80px;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background-image: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
    z-index: -1;
  }
}
.mt-10{
  margin-top: 70px;
}
.leftSpan_circle {
  position: relative;
  color: #fff;
  &::after {
    position: absolute;
    content: "";
    top: -70px;
  right: -10px;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    background-image: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
    z-index: -1;
  }
}

@media screen and (max-width: 1400px){
  h2{
    font-size: 50px;
  }
  .rightSpan_circle::after {
    width: 110px !important;
    height: 110px;
    top: -37px;
    left: -10px;
   
}

.leftSpan_circle::after {
  top: -39px;
  right: -12px;
  width: 130px !important;
  height: 130px;

}
}
@media screen and (max-width: 600px){
  h1{
    font-size: 35px;
  }
  h2{
    font-size: 35px !important;
  }
  h4{
    font-size: 30px;
  }
  .rightSpan_circle::after {
    width: 80px !important;
    height: 80px;
    top: -30px;
    left: -10px;
}
h3{
  font-size: 40px;
}

.leftSpan_circle::after {
  top: -25px;
  right: -10px;
  width: 80px;
  height: 80px;
}
}