.future-wrapper {
  //padding: 20px;
  //border: 2px solid green;
    margin-top: 60px;
  padding: 80px;
  height: auto;
  padding-bottom: 210px !important;
  position: relative;
  margin-bottom: 50px;
  overflow: hidden;

  .futureSideBarleft {
    position: absolute;
    top: 0;
    left: 0;
  }

  .futureSideBarright {
    position: absolute;
    top: 0;
    right: 0;
  }
  h2 {
    text-transform: uppercase;
    span {
      &::after {
        top: -50px;
        right: -1px;
        width: 170px;
        height: 170px;
      }
    }
  }
  .excellenceHead {
    margin-top: 60px;
    max-width: 800.35px;
    color: #2b2b2b;
    font-family: Arial;
    font-size: 61px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    text-align: left;
    span {
      background: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  .contentContainer {
    margin-top: 100px;
    padding: 10px;
    //border: 1px solid purple;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    .content-left {
      width: 50%;
      height: 840px;
      //border: 1px solid orange;
      background-image: url("../../../../public/images/home/future-img.webp");
      background-position: center;
      background-repeat: no-repeat;
    }

    .content-right {
      width: 50%;
      height: 840px;
      //border: 1px solid rgb(23, 196, 226);
      p {
        color: #707070;
        text-align: left;
      }

      .right-box-data {
        padding: 10px;
        //border: 1px solid red;
        margin-top: 40px;
        .data1 {
          display: flex;
          align-items: center;
          //justify-content: space-between;
          gap: 30px;
          padding: 10px;
          // border: 1px solid blue;
          margin-bottom: 30px;

          .right-box-data-Pleft {
            border-radius: 50%;
            min-width: 90px;
            min-height: 90px;
            background-image: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .right-box-data-Pright {
            h5 {
              margin-bottom: 7px;
            }
          }
        }
      }
    }
  }

  .absoluteDiv {
    position: absolute;
    width: 1261px;
    height: 492px;
    border-radius: 22px;
    background: #fafafa;
    box-shadow: -10.505px 10px 27.2px 0px rgba(0, 0, 0, 0.25);
    bottom: 0px;
    right: 80px;
    padding: 10px;
    display: flex;
    //align-items: center;
    justify-content: space-between;
    gap: 27px;
    //background-color: #00c6ff;

    .absolute-div-left {
      //border: 1px solid red;
      width: 283px;
      height: 321px;
      background-image: url("../../../../public/images/home/absolute-img.png");
      background-position: center;
      background-repeat: no-repeat;
    }

    .absolute-div-right {
      width: calc(100% - 290px);

      p {
        color: #2b2b2b;
        font-family: Inter;
        font-size: 31px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 37.2px */
        margin-top: 40px;
        text-align: left;
        margin-bottom: 20px;
      }
      .progressBar1 {
        position: relative;
        width: 100%;
        height: 10px;
        background: rgb(204, 199, 199);
        border-radius: 8px;

        &::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          background-image: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
          height: 10px;
          width: 92%;
          border-radius: 8px;
        }

        &::before {
          content: url("../../../../public/images/home/icons8-circle-24.png");
          width: 30px;
          height: 30px;
          position: absolute;
          padding-top: 6px;
          top: -10px;
          right: 65px;
          border-radius: 50%;
          background-color: #0072ff;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 22;
        }
      }

      .progressBar2 {
        position: relative;
        width: 100%;
        height: 10px;
        background: rgb(204, 199, 199);
        border-radius: 8px;

        &::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          background-image: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
          height: 10px;
          width: 97%;
          border-radius: 8px;
        }

        &::before {
          content: url("../../../../public/images/home/icons8-circle-24.png");
          z-index: 222;
          padding: 3px;
          width: 30px;
          height: 30px;
          position: absolute;
          top: -10px;
          right: 20px;
          border-radius: 50%;
          background-color: #0072ff;

          // &::before {
          //   content: "";
          //   width: 20px; /* Adjust the size of the white circle */
          //   height: 20px; /* Adjust the size of the white circle */
          //   position: absolute;
          //   top: 0; /* Adjust the positioning of the white circle */
          //   right:0px; /* Adjust the positioning of the white circle */
          //   border-radius: 50%;
          //   background-color: white; /* Set the color of the white circle */
          // }
        }

       
      }

      .progressBar3 {
        position: relative;
        width: 100%;
        height: 10px;
        background: rgb(204, 199, 199);
        border-radius: 8px;

        &::after {
          position: absolute;
          content:"";
          top: 0;
          left: 0;
          background-image: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
          height: 10px;
          width: 85%;
          border-radius: 8px;
        }

        &::before {
          content: url("../../../../public/images/home/icons8-circle-24.png");
          z-index: 22;
          padding: 3px;
          width: 30px;
          height: 30px;
          position: absolute;
          top: -10px;
          right: 120px;
          border-radius: 50%;
          background-color: #0072ff;

          // &::before {
          //   content: " ";
          //   width: 15px;
          //   height: 15px;
          //   position: absolute;
          //   top: -10px;
          //   right: 350px;
          //   border-radius: 50%;
          //   background-color: white;
          //   z-index: 999;
          // }
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .future-wrapper{
    
    .excellenceHead{
      font-size: 40px;
    }
  
   .absoluteDiv {
    width: 868px;
    height: auto;
    border-radius: 22px;
padding-bottom: 33px;
    bottom: -58px;
    right: 80px;
    .absolute-div-right{
      p{
        font-size: 25px;
        margin-top: 30px;
      }
    }
  }


.futureSideBarleft{
  display: none;
}
.futureSideBarright{
  display: none;
}
  }

  .future-wrapper h2 span::after {
    top: -33px;
    right: -1px;
    width: 120px !important;
    height: 120px !important;
}
}

@media screen and (max-width: 1440px){
  .future-wrapper{
      h2{
          font-size: 40px !important;
         .rightSpan_circle{
              &::after{
                  width: 120px !important;
                  height: 120px;
                  top: -40px;
                  left: -10px;
              }
          }
      }
  }
  .future-wrapper .absoluteDiv {
  
    bottom: 34px;
    right: 80px;
}
}
@media screen and (max-width: 600px) {
  .future-wrapper {
    
    padding: 20px;
    padding-bottom: 0 !important;
    h2 {
      margin-bottom: 20px;
      font-size: 35px !important;
      .rightSpan_circle{
        color: #2B2B2B;
        &::after {
       display: none;
        }
      }
    }
    .futureSideBarright {
      display: none;
    }
    .futureSideBarleft {
      display: none;
    }

    .excellenceHead {
      font-size: 30px !important;
    }
    .contentContainer {
      flex-direction: column;
      gap: 50px;
      .content-left {
        width: 100%;
        height: 400px;
      }
      .content-right {
        width: 100%;
        height: auto;
        p{
          font-size: 20px;
        }
      }
    }
    .absoluteDiv {
      display: none;
    width: 100%;
    flex-direction: column;
    .absolute-div-left{
      width: 100%;
    }
    .absolute-div-right{
      width: 100%;
    }
    }
  }

  .future-wrapper
    .contentContainer
    .content-right
    .right-box-data
    .data1
    .right-box-data-Pleft {
      max-width: 85px;
      width: 100%;
  }
}
