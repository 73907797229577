.blogBanner{
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url("../../../assets/images/blogbannerback.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
    background-color: black;

    button{
        border-radius: 6px;
        border: 2.43px solid #FFF;
        background: transparent;
        color: #FFF;
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 184px;
        height: 56px;
        margin-top: 70px;
    }
}

@media screen and (max-width:1300px) {
    .blogBanner{
        height: 500px;
        padding-top: 50px;
        h1{
            font-size: 60px;
        }
        button{
            margin-top: 30px;
        }
    }
}
@media screen and (max-width:600px) {
    .blogBanner{
        height: 350px;

        h1{
            font-size: 40px;
        }
        button{
            width: 160px;
            height: 45px;
        }
    }
}