.missionWrapper {
  padding: 20px;
  //border: 1px solid green;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  

 .glowLeft{
  position: absolute;
  top: 0;
  left: 0;
 }
 .glowRight{
  position: absolute;
  top:0;
  right: 0;
 }
  .missionLeft {
    padding: 20px;
    width: 46%;
    //margin-top: 30px;
    height: auto;
    //border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: 500.998px;
      height: 500px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 4;
      border: 2px solid transparent;
      transition: border-color 0.3s ease;
    }
    .centerDiv {
      width: 600.331px;
      height: 600.331px;
      //transform: rotate(135deg);
      flex-shrink: 0;
      border-radius: 136px;
      background: rgba(0, 172, 222, 0.16);
      transform: rotate(45deg);
      animation: customAni 4s linear 2s infinite forwards;

     
    
      //transition-delay: 3s !important;

      //   @keyframes customAni {
      //     0% {
      //       transform: rotate(0);
      //       transform-origin:center;
      //     }

      //     100% {
      //       transform: rotate(360deg);
      //       transform-origin: center;
      //     }
      //   }





    //   @keyframes customAni {
    //     0% {
    //       transform: scaleY(1) rotate(0);
    //       transform-origin: center;
         
    //     }

    //     50% {
    //       border-radius: 50%;
    //       transform: scaleY(0.9) rotate(90deg);
    //       opacity: 0.7;
    //     }

    //     100% {
    //       transform: scaleY(1) rotate(0);
    //       transition-delay: 5s;
    //       transition-duration: 3s;
    //     }
    //   }






    @keyframes customAni {
        0% {
            transform: scaleY(1) rotate(0);
            transform-origin: center;
            border-color: transparent;
          }
  
          25% {
            transform: scaleY(0.9) rotate(90deg);
            border-radius: 50%;
            opacity: 0.7;
            border-color: transparent;
          }
  
          50% {
            transform: scaleY(1) rotate(180deg);
            border-color: transparent;
          }
  
          75% {
            transform: scaleY(0.9) rotate(270deg);
            border-radius: 50%;
            opacity: 0.7;
            border-color: transparent;
          }
  
          100% {
            transform: scaleY(1) rotate(360deg);
            transition-delay: 2s;
            transition-duration: 3s;
            border-color: #b61313; /* Add the desired border color here */
          }
      }








    }
  }

  .missionRight {
    padding: 20px;
    width: 46%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    //border: 2px solid black;

    p {
      max-width: 567.006px;
      width: 100%;
      color: #707070;
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 10px;
      text-align: left;
    }
  }
}


@media screen and (max-width:1550px){
  .missionWrapper {
    justify-content: space-around;
    .missionRight {
      width: 43%;
      p {
    max-width: 500.006px;
    width: 100%;
    font-size: 18px;
    text-align: left;
  }
}
}
  
}

@media screen and (max-width:1370px){
  .missionWrapper {
    //border: 1px solid green;
    margin: 0 auto !important;
    
    .missionLeft {
      img{
        width: 350px;
        height: 350px;
      }
      .centerDiv {
    width: 408.331px;
    height: 408.331px;
    animation: none;
}
  }
}

}

@media screen and (max-width:600px){
  .missionWrapper{
    flex-direction: column;
    margin-top: 0;
    gap: 30px;
    .glowLeft{
    display: none;
     }
     .glowRight{
     display: none;
     }
    .missionLeft{
      width: 100%;
      img{
        width: 250px;
        height: 250px;
      }
      .centerDiv{
        width: 300px;
        height: 300px;
      
      }
    }
    .missionRight{
      width: 100%;
      h2{
        
      }
      p{
        font-size: 17px;
        margin-top: 20px;
      }
    }
  }

}