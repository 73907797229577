.faqWrapper {
  padding: 20px;
  //border: 1px solid green;
  overflow: hidden;
  padding-top: 60px;

  h2 {
    text-transform: uppercase;
    font-size: 60px !important;
    .rightSpan_circle {
      &::after {
        top: -45px;
        right: -1px;
        width: 150.608px !important;
        height: 150.608px !important;
      }
    }
  }

  .faq-container {
    width: 100%;
    padding: 20px 50px;
    //border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url("../../../../public/images/home/faqBg.png");
    background-repeat: no-repeat;
    background-position: 100% 20%;
    // flex-wrap: wrap;

    .faq-left {
      width: 50%;
      padding: 10px;
      //border: 2px solid red;

      .accordianHeader {
        color: GREEN !important;
        font-family: Arial;
        font-size: 25px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 127.273% */
        border: none !important;
      }

      .accordion-item:first-of-type .accordion-button {
        color: #2b2b2b;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Arial;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        // border-top-right-radius: 22px;
        // border-top-left-radius: 22px;
        border-radius: 22px;
        line-height: 28px; /* 127.273% */
        //border: 1px solid firebrick;
        border-bottom: none;
        outline: none !important;
      }

      .accordion-item {
        margin-bottom: 20px;
        border-radius: 22.031px;
        background: #fff;
        box-shadow: 0px 0px 17.135px 0px rgba(0, 198, 255, 0.25);
        border-radius: 22px;
      }
      .accordion-button {
        color: #2b2b2b;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Arial;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 127.273% */
        padding: 50px 0px 50px 40px;
        border-radius: 22px;
        border: none;
        //margin-bottom: 30px;

        &::after {
          margin-right: 30px;
          background-color: #ffffff;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid #018eff;
          //border-color: #018eff !important;
          //content: url("../../../../public/images/home/icons8-right-arrow-48.png");
          content: url("../../../../public/images/home/icons8-forward-20.png");
          transform: rotate(0deg);
          transition: all 0.7s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 7px;
          background-image: none;
        }
      }

      .accordion-button:not(.collapsed) {
        background: white;
        border-bottom: none;
      }
      .accordion-button:not(.collapsed)::after {
        transform: rotate(0deg) !important;
        content: url("../../../../public/images/home/faq-arrow.png");
        // box-shadow: 0px 0px 17.135px 0px rgba(0, 198, 255, 0.25);
        background: #018eff;
        border: 1px solid gainsboro !important;
        padding-top: 0px;
      }
      .faqWrapper .faq-container .faq-left .accordion-button:not(.collapsed)::after {
        transform: rotate(-100deg) !important;
        content: url(http://localhost:3000/static/media/blue-icon.48de5197b73fa0ebb8a6.svg);
        background: white;
        border: 1px solid gainsboro !important;
    }
    .accordion-button:focus {
      z-index: 3;
      border-color: none;
      outline: none;
      box-shadow: none;
  }
      .accordion-body {
        color: #707070;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
      }
    }
    .faq-right {
      width: 50%;
      height: 800px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      //border: 1px solid blue;

      img {
        //border: 1px solid green;
        margin-top: 30px;
        height: 100%;
        //height: 660px;
      }
    }
  }
}

@media screen and (max-width: 1760px) {
  .faqWrapper {
    .faq-container {
      .faq-right {
        width: 40%;
        img {
          width: 600px;
          margin-top: 30px;
          height: 600px;
        }
      }
    }
  }
}
@media screen and (max-width: 1550px){
  .faqWrapper{
    h2{
      font-size: 45px !important;
      .rightSpan_circle{
        &::after{
            width: 120px !important;
            height: 120px !important;
            top: -35px;
            left: 1px;
        }
    }
    }

  
  }
} 
@media screen and (max-width: 1400px) {
  .faqWrapper {
    .faq-container {
      .faq-right {
        width: 40%;
        //border: 1px solid red;
        img {
          width: 500px;
          margin-top: 0px;
          height: 500px;
        }
      }
    }
  }
}
  .faqWrapper{
      h2{
          font-size: 40px;
          span{
              &::after{
                  width: 100px !important;
                  height: 100px !important;
                  top: -30px;
                  left: 1px;
              }
          }
      }
  }


@media screen and (max-width: 600px) {
  .faqWrapper {
 
.h-center{
  display: block;
}
    h2{
      font-size: 35px !important;
      width: 100%;
      span{
        color: #2B2B2B;
        
        &::after{
          display: none;
        //  width: 30px !important;
        //  height: 30px !important;
        }
      }
    }
    .faq-container {
      width: 100%;
      padding: 20px;
      //border: 1px solid black;
      display: flex;
      align-items: center;
      flex-direction: column;
      .faq-right {
       display: none;
        img {
          width: 300px;
          height: 300px;
        }
      }
      .faq-left {
        width: 100%;
      }
    }
  }

  .faqWrapper h2 span::after {
    top: -27px;
    right: -11px;
    width: 85.608px !important;
    height: 85.608px !important;
  }
}
