.card-wrapper {
  width: 400.229px;
  height: 400.453px;
  border-radius: 22.031px;
  background: #fff;
  box-shadow: 0px 0px 17.135px 0px rgba(0, 198, 255, 0.25);
  margin-top: 88px !important;
  position: relative;
  margin: 0 auto;
  overflow: hidden;

  .cardElements {
    width: 100%;
    height: 100%;
    border-radius: 22.031px;
    background: #fff;
    box-shadow: 0px 0px 17.135px 0px rgba(0, 198, 255, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    //border: 1px solid green;
    .circle {
      width: 140.693px;
      height: 140.693px;
      border-radius: 50%;
      background: linear-gradient(180deg, #00c6ff 0%, #0072ff 100%);
      // margin-bottom: 20px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // padding: 10px;
      position: absolute;
      top: 15%;
      z-index: 1;
      transition: all 0.7s ease-in;
    }
    .blockImgDiv {
      // width: 140.693px;
      // height: 140.693px;
      // border-radius: 50%;
      // background: linear-gradient(180deg, #00c6ff 0%, #0072ff 100%);
      // margin-bottom: 20px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // padding: 10px;

      // transition: all 0.7s ease-in;
      img {
        display: flex;
        // border: 1px solid red;
        position: relative;
        z-index: 2;
      }
    }
    h4 {
      margin-top: 45px;
      text-align: center;
      padding: 0 30px;
      width: 80%;
      position: relative;
      height: 92px;
      z-index: 3;
    }
    &:hover {
      .circle {
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 0;
        margin-bottom: 0;
        transform: scale(1.7);
      }
      .blockImgDiv {
        // width: 100%;
        // height: 100%;
        // position: absolute;
        // border-radius: 0;
        // margin-bottom: 0;
        // transform: scale(1.15);

        // img {
        //   position: absolute;
        //   margin-top: 50px;
        //   top: 20px;

        //   width: 110.693px;
        //   height: 110.693px;
        // }
      }
      h4 {
        color: white;
        

        // bottom: 10%;
        // left: 50%;
        // transform: translate(-50%, -40%);
        // position: absolute;
        // margin-top: 40px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .card-wrapper {
    height: 360px;
    width: 300px !important;
    margin-top: 40px !important;
    h4 {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .card-wrapper {
    width: 350px;
    height: 370px;
    h4 {
      font-size: 28px;
    }
    .cardElements {
      .blockImgDiv {
        width: 100px;
        height: 100px;

        img {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
}
