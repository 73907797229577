
.card-main-container{
    position: relative;


.card-inner-content{
    padding: 82px 0 52px 0;
    width: 80%;
    margin: auto;
    .inner-back-container{
        width: 85%;
        img{
            width: 100%;
        }
    }
    .inner-upper-container{
        width: 90%;
        margin-left: auto;
        margin-top: -20%;
        .upper-header{
            // margin-left: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            h2{
                color: rgba(255, 255, 255, 0.71);
                text-shadow: 5px 4px 9px rgba(0, 0, 0, 0.27);
                font-family: "Arial";
                font-size: 74px;
                font-weight: 700;
                max-width: 865px;
                text-align: center;
            }
        }

        .upper-content{
            display: flex;
            flex-direction: column;
            margin-left: auto;
            border-radius: 29px;
            background: rgba(255, 255, 255, 0.88);
            box-shadow: 5px 4px 9px 0px rgba(0, 0, 0, 0.27);
            backdrop-filter: blur(34.66666793823242px);
            padding: 52px 67px 55px 85px;
            h1{
                color: var(--UI-dark, #222);
                font-family: "Inter";
                font-size: 44.021px;
                font-weight: 700;
                line-height: normal;
            }
            p{
                color: var(--600, #718096);
                font-family: "Inter";
                font-size: 22px;
                font-weight: 400;
                line-height: normal;
                text-align: start;
                padding-top: 20px;
            }
            span{
                color: var(--600, #718096);
                font-family: "Inter";
                font-size: 22px;
                font-weight: 400;
                line-height: normal;
                text-align: start;
                padding-top: 20px;
            }
        }
    }
}
.inner-bg-container{
    position: absolute;
    // display: none;
    top: 0;
    right: 0;
    z-index: -4;

    img{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
}

@media screen and (max-width:1800px) {
    .card-inner-content{
        .inner-upper-container{
            .upper-header{
                h2{
                    font-size: 55px !important;
                    max-width: 700px;
                }
            }
        }
    }
}
@media screen and (max-width:1500px) {
    .card-inner-content{
        .inner-upper-container{
            .upper-header{
                h2{
                    font-size: 45px !important;
                    max-width: 600px !important;
                }
            }
            .upper-content{
                padding: 40px 50px !important;
                h1{
                    font-size: 35px  !important;
                }
                p{
                    font-size:18px   !important;
                    padding-top: 15px  !important;
                    margin-bottom: 0  !important;
                }
                span{
                    font-size: 18px  !important;
                    padding-top: 15px  !important;
                }
            }
        }
    }

    
}


@media screen and (max-width:1200px) {
    .card-inner-content{
        width: 95% !important;
        .inner-upper-container{
            .upper-header{
                h2{
                    font-size: 45px !important;
                }
            }
            .upper-content{
                padding: 30px 40px;
                h1{
                    font-size: 30px;
                }
                p{
                    font-size:16px;
                    padding-top: 15px;
                    margin-bottom: 0;
                }
                span{
                    font-size: 16px;
                    padding-top: 15px;
                }
            }
        }
    }

    
}

@media screen and (max-width:600px) {
    .card-inner-content{
        width: 95%  !important;
        padding: 30px 0 30px 0  !important;

        .inner-back-container{
            width: 100%  !important;
        }
        .inner-upper-container{

            margin: auto  !important;
            margin-top: -25%  !important;
            .upper-header{
                margin-left: 0  !important;
                h2{
                    font-size: 27px  !important ;
                }
            }
            .upper-content{
                padding: 20px 15px  !important;
                h1{
                    font-size: 22px  !important;
                }
                p{
                    font-size:15px  !important;
                    padding-top: 10px  !important;
                    margin-bottom: 0;
                }
                span{
                    font-size: 15px  !important;
                    padding-top: 10px  !important;
                }
            }
        }
    }
    .inner-bg-container{
        display: none;
    }
    
}
