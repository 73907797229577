.portfolioCards {
  width: 479px;
  margin: 10px;
  height: 292.71px;
  position: relative;
  border-radius: 22px;
  overflow: hidden;
  background: linear-gradient(180deg, #0000, #423e3e);
  cursor: pointer;

  &::after {
    content: " ";
    height: 200px;
    background: linear-gradient(180deg, #0000, #423e3e);
    position: absolute;
    width: 100%;
    z-index: 2;
    bottom: 0;
    left: 0;
  }

  img {
    object-fit: cover;
    transition: transform 7s ease-in-out;
    position: relative;
    width: 100%;
  }

  .eyeIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: white;
    z-index: 4;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .projInfo {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 180px;
    display: flex;
    padding-left: 20px;
    flex-direction: column;
    z-index: 3;
    opacity: 1; 
    transition: opacity 0.3s ease-in-out; 
  }

  &:hover {
    background-color: transparent;
    &::after {
      background: none;
    }

    img {
      transform: translateY(calc(-100% + 290px));
    }

    .projInfo {
      opacity: 0;
    }

    .eyeIcon {
      opacity: 1;
      background-image: linear-gradient(90deg, #00C6FF 0%, #0072FF 100%);
      color: #fff;
      font-size: 28px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 12px;
    }
  }
}

.projInfo h4 {
  text-align: left;
  font-size: 36px;
  color: white;
}

.projInfo p {
  text-align: left;
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  .portfolioCards {
    width: 100%;
    margin: 0;

    img {
      width: 100%;
    }

    .projInfo {
      height: 200px;
      padding: 20px;
      bottom: 20px;

      h4 {
        font-size: 30px;
      }

      p {
        font-size: 14px !important;
      }
    }

    .eyeIcon {
      font-size: 1.5rem;
    }
  }
}
