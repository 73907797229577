.h-banner {
  width: 100%;
  height: 100vh;
  //border: 2px solid purple;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: black;



  .sideGlow {
    position: absolute;
    //border: 1px solid red;
    width: 900px;
height: 684.673px;
//transform: rotate(-90deg);
border-radius: 1026.423px;
opacity: 0.8;
background: radial-gradient(50% 50% at 50% 50%, #0184FF 0%, rgba(0, 156, 205, 0.00) 100%);
    top: 10%;
    left: -23%;
    // left: 0;
    z-index: 3333 !important;
    // animation: customAni 3s ease-in 2s infinite alternate-reverse both;
    animation: myAnim 5s ease-in  infinite alternate-reverse ;
    // animation: customAni 2s ease 2s infinite alternate-reverse;
  }


  // @keyframes customAni {
  //   0% {
  //     transform: translateY(0);
  //     }
    
  //     100% {
  //     transform: translateY(-100px);
  //     }
  //   }
    
  // @keyframes customAni {
  //   0% {
  //     transform: translateX(0px);
  //     // left: 10%;
  //     //transition-delay: 2s;
  //     }
  //   50%{
  //     transform: translateX(50px);
  //   }
  //     100% {
  //     transform: translateX(-100px);
  //     // left: -33px;
  //     }
  //   }

  @keyframes myAnim {
    0% {
      transform: translateX(0px);
      transition-duration: 5s;
      left: -30%;
      opacity: 0.5s;
    }
    50%{
      transform: translateX(-50px);
      transition-duration: 4s;
      opacity: 0.8s;
      left: -40%;
    }
  
    100% {
      transform: translateX(-100px);
      opacity: 1s;
      left: -30%;
    }
  }

  
  .sideGlowRight {
    position: absolute;
    //border: 1px solid red;
    width: 900px;
height: 684.673px;
//transform: rotate(-90deg);
border-radius: 1026.423px;
opacity: 0.8;
background: radial-gradient(50% 50% at 50% 50%, #0184FF 0%, rgba(0, 156, 205, 0.00) 100%);
    top: 10%;
    right: -23%;
    // left: 0;
    z-index: 3333 !important;
    // animation: customAni 3s ease-in 2s infinite alternate-reverse both;
    animation: myAnimation 5s ease-in infinite alternate-reverse ;
    // animation: customAni 2s ease 2s infinite alternate-reverse;
  }
  @keyframes myAnimation {
    0% {
      transform: translateX(0px);
      transition-duration: 5s;
      right: -30%;
      opacity: 0.5s;
    }
    50%{
      transform: translateX(-50px);
      transition-duration: 4s;
      transition-delay: 3s;
      opacity: 0.8s;
      right: -40%;
    }
  
    100% {
      transform: translateX(-100px);
      opacity: 1s;
      right: -30%;
    }
  }

  .banner-center-div {
    width: 80%;
    //border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px !important;
    position: relative;
    z-index: 6;
    color: var(--white);
    .banner-head {
      max-width: 1994.533px !important;
      text-align: center;
      font-family: Arial;
      font-size: 74px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      //border: 1px solid red;
    }
    p {
      text-align: center !important;
      max-width: 950px !important;
      margin: auto;
      //border: 1px solid red;
      // text-transform: capitalize;
      font-size: 22px;
    }
    a{
      text-decoration: none !important;
      outline: none;
      .gradient-blue-button {
        margin-top: 28px !important;
      }
    }
 
  }

  // .semiCircleGlow {
  //   position: absolute;
  //   top: 0;
  //   left: -100%; /* Initially outside the container */
  //   width: 200%; /* Make it wider to cover the whole width when rotated */
  //   height: 100%;
  //   background: radial-gradient(circle at right, rgba(0, 0, 255, 0.5) 0%, rgba(0, 0, 255, 0) 100%);
  //   transform: rotate(180deg); /* Rotate to form a semi-circle */
  //   z-index: 2;
  //   animation: moveSemiCircleGlow 3s infinite;
  // }

  // @keyframes moveSemiCircleGlow {
  //   0%, 100% {
  //     left: -100%;
  //   }

  //   50% {
  //     left: 100%;
  //   }
  //}

  




  // @keyframes myAnim {
  //   0% {
  //     transform: translateX(0);
  //   }
  
  //   100% {
  //     transform: translateX(-100px);
  //   }
  // }
 

  .hero-vid {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 2;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: "";
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 1660px) {
  .h-banner .banner-center-div .banner-head {
    font-size: 65px;
  }
}

@media screen and (max-width: 1400px){
  .h-banner {
    .sideGlow{
      display: none;
    }
    .sideGlowRight{
      display: none;
    }
    padding-bottom: 200px;
    .banner-center-div {
    .banner-head {
      margin-top: 300px;
      font-size: 45px;
  }
}
}

.brand-wrapper h2 span::after {
  top: -31px;
  right: 0px;
  width: 130px;
  height: 130px;
}
}

@media screen and (max-width: 600px){
  .h-banner {
    padding-top: 150px;
    height: 500px;
    .sideGlow{
      display: none;
    }
    .sideGlowRight{
      display: none;
    }
    .banner-center-div {
      p{
        font-size: 18px;
      }
      .banner-head {
  margin-top: 50px;
   font-size: 30px;
  }
}
}

.brand-wrapper h2 span::after {
  top: -20px;
  right: -7px;
  width: 100px;
  height: 100px;
}

.brand-wrapper .brand-logo-Container {
  width: 100%;
  padding: 20px;
  gap: 30px;
  flex-direction: column;
 
}
}