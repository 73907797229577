.services-top-main{
    position: relative;
    overflow: hidden;
    .services-main-container{
        width: 80%;
        margin: auto;
        padding: 100px 0;
        .our-serviceshead-container{
            display: flex;
            justify-content: center;
            align-items: center;
            .our-left{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 172.207px;
                height: 172.207px;
                background: var(--Button, linear-gradient(90deg, #00C6FF 0%, #0072FF 100%));
                border-radius: 50%;
    
                h2{
                    color: #FFF;
                    font-family: "Arial";
                    font-size: 61px;
                    font-weight: 700;
                    line-height: 120%; 
                    margin-left: 20%;
                }
            }
            .our-right{
                h2{
                    color: #2B2B2B;
                    font-family: "Arial";
                    font-size: 61px;
                    font-weight: 700;
                    line-height: 120%;
                }
    
            }
        
        }
    
        .services-card-maincontainer{
            display: flex;
            gap: 8%;
            justify-content: center;
            align-items: center;
            margin-top: 80px;
    
            .service-card-left{
                width: 50%;
    
                .service-btn{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 11.036px;
                    border: 1.38px solid #2B2B2B;
                    width: 140px;
                    height: 54px;
                    border-radius: 11.036px;
                    border: 1.38px solid #2B2B2B;
                    cursor: pointer;
    
                    p{
                        color: #2B2B2B;
                        font-family: "Arial";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        letter-spacing: 1.8px;
                        margin: 0 !important;
                    }
                }
                h2{
                    color: #2B2B2B;
                    font-family: "Arial";
                    font-size: 61px;
                    font-weight: 700;
                    line-height: 60.694px; 
                    letter-spacing: -1.22px;
                    margin-top: 55px;
                }
                p{
                    color: #2B2B2B;
                    font-family: "Inter";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%; 
                    text-align: start;
                    margin-top: 22px;
                }
                .service-left-3{
                    .left3-row{
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        gap: 11px;
                        margin-top: 30px;
    
                        img{
                            width: 30px;
                            height: 33px;
                        }
                        h3{
                            color: #2B2B2B;
                            font-family: "Arial";
                            font-size: 22px;
                            font-weight: 700;
                            line-height: normal;
                            letter-spacing: 2.2px;
                            text-transform: uppercase;
                            margin: 0;
                        }
                    }
                }
                .getin-touch-btn{
                    margin-top: 55px;
    
                    button{
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 600;
                        text-transform: capitalize;
                        border-radius: 6px;
                        background: var(--Button, linear-gradient(90deg, #00C6FF 0%, #0072FF 100%));
                        width: 240px;
                        height: 60px;
                        border: none;
                        cursor: pointer;
                        &:hover{
                            background: linear-gradient(90deg, #0072FF 0%, #00C6FF 100%);
                        }
                    }
                }
            }
            .service-card-right{
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 100%;
                    margin: auto;
                }
            }
    
            &:nth-child(3), &:nth-child(5), &:nth-child(7){
                flex-direction: row-reverse;
            }
        }
    }

    .services-background{
        position: absolute;
        left: 0;
        top: 0;
        z-index: -10;

        img{
            width: 100%;
            background-position: center;
            background-size: cover;
            
        }
    }
}


@media screen and (max-width:1700px) {
    .services-top-main{
        .services-main-container{
            width: 88%;

            .services-card-maincontainer{
                gap: 9%;
                .service-card-left{
                    h2{
                        font-size: 50px;
                        line-height: 50px;
                        margin-top: 33px;
                    }
                    p{
                        font-size: 20px;
                    }
                    .service-btn{
                        width: 130px;
                        height: 50px;
                        p{
                            font-size: 18px;
                        }
                    }
                        .service-left-3{
                            .left3-row{
                            margin-top: 25px;
                            h3{
                                font-size: 18px;
                            }
                            img{
                                width: 28px;
                                height: 28px;
                            }

                            }
                        }
                        .getin-touch-btn{
                            margin-top: 33px;
                            button{
                                width: 220px;
                                height: 55px;
                                font-size: 20px;
                            }
                        }
                }
            }
            .our-serviceshead-container{
                .our-left{
                    height: 160px;
                    width: 160px;
                    h2{
                        font-size: 55px;
                    }
                }
                .our-right{
                    h2{
                        font-size: 55px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1500px) {
    .services-top-main{
        .services-main-container{
            width: 90%;

            .services-card-maincontainer{
                gap: 7%;
                .service-card-left{
                    h2{
                        font-size: 40px;
                        line-height: 50px;
                        margin-top: 33px;
                    }
                    p{
                        font-size: 17px;
                    }
                    .service-btn{
                        width: 120px;
                        height: 47px;
                        p{
                            font-size: 16px;
                        }
                    }
                        .service-left-3{
                            .left3-row{
                            margin-top: 15px;
                            h3{
                                font-size: 16px;
                            }
                            img{
                                width: 25px;
                                height: 25px;
                            }

                            }
                        }
                        .getin-touch-btn{
                            margin-top: 33px;
                            button{
                                width: 200px;
                                height: 50px;
                                font-size: 18px;
                            }
                        }
                }
            }
            .our-serviceshead-container{
                .our-left{
                    height: 150px;
                    width: 150px;
                    h2{
                        font-size: 50px;
                    }
                }
                .our-right{
                    h2{
                        font-size: 50px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width:1200px) {
    .services-top-main{
        .services-main-container{
            .services-card-maincontainer{
                gap: 5%;

                .service-card-left{
                    h2{
                        font-size: 35px;
                        margin-top: 15px;
                    }
                    p{
                        font-size: 15px;
                        margin-top: 15px;
                    }
                    .service-left-3{
                        .left3-row{
                            gap: 7px;
                            h3{
                                font-size: 15px;
                            }
                            img{
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                    .getin-touch-btn{
                        button{
                            width: 185px;
                            height: 45px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:600px) {
    .services-top-main{
        .services-main-container{
            width: 90%;
            padding: 40px 0;

            .our-serviceshead-container{
                .our-left{
                    height: 95px;
                    width: 95px;

                    h2{
                        font-size: 30px !important;
                    }
                }
                .our-right{
                    h2{
                        font-size: 30px !important;
                    }
                }
            }

            .services-card-maincontainer{
                flex-direction: column !important;
                gap: 30px;
                margin-top: 45px;

                .service-card-left{
                    width: 100%;

                    .service-btn{
                        height: 40px;

                        p{
                            font-size: 15px;
                            letter-spacing: unset;
                        }
                    }
                    h2{
                        font-size: 22px !important;
                        line-height: normal;
                        text-align: start;
                    }
                    p{
                        font-size: 13px;
                        margin-top: 10px;
                        line-height: normal;
                    }
                    .service-left-3{
                        .left3-row{
                            margin-top: 7px;
                            h3{
                                font-size: 13px;
                                letter-spacing: unset;
                            }
                        }
                    }
                    .getin-touch-btn{
                        
                        button{
                            width: 160px;
                            height: 40px;
                            font-size: 15px;
                        }
                    }
                }
                .service-card-right{
                    width: 100%;
                }
            }
        }

        .services-background{
            img{
                display: none;
            }
        }
    }
}