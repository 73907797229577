.voice-wrapper {
  padding: 20px 90px;
  //border: 2px solid red;
  margin-top: 160px;
  padding-bottom: 160px;
  background-image: url("../../../../public/images/home/testimonialPic.png");
  background-position: 100% 40%;
  background-repeat: no-repeat;
  background-size: contain;
  // position: relative;
  // z-index: -1;
  padding-top:50px ;
  overflow: hidden;


  h2 {
    text-transform: uppercase;
    margin-bottom: 70px;
    span {
      &::after {
        top: -35px;
        right: -5px;
        width: 130px !important;
        height: 130px;
      }
    }
  }
}

@media screen and (max-width:1680px){

  .voice-wrapper{
    padding: 20px;
    padding-bottom: 150px;
    //border: 2px solid green;
  }
}
@media screen and (max-width:1550px) {
  .voice-wrapper{
    padding-top: 40px;
  }
  .voice-wrapper h2 span::after {
    top: -28px;
    right: -5px;
    width: 100px !important;
    height: 100px !important;
}
  
}
// @media screen and (max-width:1400px){

// .voice-wrapper {
//   h2 {
//     font-size: 40px;
//   span::after {
//     top: -26px;
//     right: -5px;
//     width: 100px !important;
//     height: 100px !important;
// }
// }
// }
// }

@media screen and (max-width:600px){
 .voice-wrapper{
  background-image: none;
  margin-bottom: 20px;
  padding-bottom: 100px;
  margin-top: 0;
  height: auto;


  h2 {
    font-size: 40px;
    margin-bottom: 0;
  span{
    color: #2B2B2B;
    &:after {
    // top: -26px;
    // right: -13px;
    // width: 98px !important;
    // height: 98px !important;
    display: none;
    }
}
}
 }

//  .voice-wrapper {
//   h2 {
//     span {
//       color: black;
//       &:after {
//         display: none;
//       }
//     }
//   }
// }
}