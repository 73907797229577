.getInTouch-Wrapper {
  //border: 1px solid red;
  padding: 130px 240px;
  background-image: url("../../../../public/images/contact/bgBlueShade.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 50%;

  .getInTouch-Container {
    padding: 65px 30px 100px 30px;
    //border: 1px solid black;
    border-radius: 32px;
    background: #fff;
    box-shadow: -15px 14px 37.5px 0px rgba(1, 135, 255, 0.08);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .content-left {
      padding: 40px 80px;
    //border: 2px solid purple;
      width: 50%;
      // display: flex;
      // align-items: flex-start;
      // justify-content: flex-end;
      // flex-direction: column;

      .name-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 24px;
        .f-name {
          label {
            color: #344054;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
          input {

            width: 240px;
            height: 50px;
            padding: 12px 16px;
            align-items: center;
            outline: none;
            border-radius: 8px;
            border: 1px solid #d0d5dd;
            background: white;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--Gray-500, #667085);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 133.333% */

            &::placeholder {
              color: #667085;
            }
            // &:focus{
            //     border: none;
            // }
          }
        }
        .l-name {
          label {
            color: #344054;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
          input {
            width: 240px;
            height: 50px;
            padding: 12px 16px;
            align-items: center;
            border-radius: 8px;
            border: 1px solid #d0d5dd;
            background: white;
            outline: none;

            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--Gray-500, #667085);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 133.333% */

            &::placeholder {
              color: #667085;
            }
            // &:focus{
            //     border: none;
            // }
          }
        }
      }

      .getInTouchButton {
        display: flex;
        width: 480px;
        padding: 16px 52px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        background: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
        color: #fff;
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        margin: 0 auto;
        border: none;
        margin-top: 33px;
        transition: 1s ease-in-out linear;

        &:hover {
          border-radius: 6px;
          background: linear-gradient(90deg, #0072ff 0%, #00c6ff 100%);
          box-shadow: 0px 4px 5.6px 0px rgba(0, 0, 0, 0.35);
          transition: 1s ease-in-out linear;
        }
      }
    }
    .content-right {
      //border: 2px solid green;
      border-radius: 16px;
      background-image: url("../../../../public/images/contact/contentRightPic.png");
      background-position: center;
      background-size: cover;
      width: 50%;
      height: 800px;
      box-shadow: -15px 14px 37.5px 0px rgba(201, 212, 223, 0.19);
    }
  }
}
.PhoneInput {

  height: 50px;
            padding: 12px 16px;
            align-items: center;
            border-radius: 8px;
            border: 1px solid #d0d5dd;
            background: white;

            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--Gray-500, #667085);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 133.333% */

            &::placeholder {
              color: #667085;
            }
}
// .PhoneInput--focus{
//   border: white !important;
// }
.PhoneInputInput{
  height: 40px;
 outline: none;
 border: none;
}

@media screen and (max-width:1878px) {
  .getInTouch-Wrapper{
    padding: 20px;
  }
  
}


@media screen and (max-width:1700px) {
  .getInTouch-Wrapper{
    background: none;
  }
}

@media screen and (max-width:1370px) {
  .getInTouch-Wrapper {
    .getInTouch-Container {
      .content-left {
    padding: 20px;
    width: 50%;
    .name-wrapper{
      flex-direction: column;
      align-items: normal;
      .f-name{
        input{
          width: 100%;
        }
      }
      .l-name{
        input{
          width: 100%;
        }
      }
    }
    .getInTouchButton{
      width: 100%;
    }
  }
}
}
}

@media screen and (max-width:600px){

  .getInTouch-Wrapper{ 
    .getInTouch-Container {
padding: 0;
    flex-direction: column;

    .content-left{
      width: 100%;
    }
    .content-right{
      width: 100%;
      height: 350px;
    }
}
  }
}