.serviceBanner{
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url("../../../assets/images/servicesbackpic.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: black;
}

@media screen and (max-width:1300px) {
    .serviceBanner{
        height: 500px;
    }
}
@media screen and (max-width:600px) {
    .serviceBanner{
        height: 350px;
    }
}