.mapConatiner{
    height: 400px;
    width: 100%;
   // background-image: url("../../../../public/images/contact/map.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 60px;
    position: relative;

    &::before{
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background-color: rgba(0, 0, 0, 0.65);
        width: 100%;
        height: 100%;
    }

    &:hover{
        &::before{
            background: none;
         
        }
    }

}