.form-wrapper {
  padding: 30px;
  padding-bottom: 60px !important;
  //border: 1px solid green;
  background-image: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
.formLeftGrid{
  position: absolute;
  top: 0;
  left: 10px;
  filter: brightness(20%);
  height: 100%;
 
}
.formRightGrid{
  position: absolute;
  top: 0;
  right: 0;
  filter: brightness(20%);
  height: 100%;
  
}
  .formBox {
    width: 880px;
    padding: 68px 40px 19px 40px;
    border-radius: 22px;
    border: 2.35px solid rgba(255, 255, 255, 0.5);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 0.09) 78.82%
    );
    box-shadow: -50.417px 50.417px 50.417px 0px rgba(255, 255, 255, 0.09) inset,
      50.417px -50.417px 50.417px 0px rgba(194, 194, 194, 0.09) inset;
    backdrop-filter: blur(38.82143783569336px);
    display: flex;
    flex-direction: column;
    gap: 24px;

    .namePhoneWrapper {
      display: flex;
      justify-content: space-between;
    }
    input {
      padding: 17px 25px;
      border-radius: 12px;
      border: 1.175px solid rgba(255, 255, 255, 0.5);
      background: rgba(255, 255, 255, 0.5);
      box-shadow: -22.016px 22.016px 22.016px 0px rgba(255, 255, 255, 0.2) inset,
        22.016px -22.016px 22.016px 0px rgba(194, 194, 194, 0.2) inset;
      backdrop-filter: blur(0px);
      color: #fff;
      font-family: Inter;
      font-size: 23.505px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: start !important;
      justify-content: flex-start;
      outline: none;
      &::placeholder {
        color: white;
      }
    }

    .description {
      padding: 17px 25px;
      border-radius: 12px;
      border: 1.175px solid rgba(255, 255, 255, 0.5);
      background: rgba(255, 255, 255, 0.5);
      box-shadow: -22.016px 22.016px 22.016px 0px rgba(255, 255, 255, 0.2) inset,
        22.016px -22.016px 22.016px 0px rgba(194, 194, 194, 0.2) inset;
      backdrop-filter: blur(0px);
      color: #fff;
      font-family: Inter;
      font-size: 23.505px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: start !important;
      justify-content: flex-start;
      outline: none;
      height: 152px;
      &::placeholder {
        color: white;
      }
    }

    button {
        margin: 0 auto;
        border: none;
        width: 172px;
      display: inline-flex;
      padding: 16px 52px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 6px;
      background: var(
        --button,
        linear-gradient(90deg, #00c6ff 0%, #0072ff 100%)
      );
      box-shadow: 0px 4px 5.6px 0px rgba(0, 0, 0, 0.35);
      color: #FFF;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;

&:hover{
    border-radius: 6px;
background: linear-gradient(90deg, #0072FF 0%, #00C6FF 100%);
box-shadow: 0px 4px 5.6px 0px rgba(0, 0, 0, 0.35);
}
    }
  }
}

@media screen and (max-width:1770px){
  .form-wrapper{
  
  }
  
}

@media screen and (max-width:600px){
  .form-wrapper {
    padding: 10px;
    h4{
      font-size: 22px;
    }
    .formLeftGrid{
    display: none;
    }
    .formRightGrid{
   display: none;
    }
    form{
      width: 100%;
      .formBox {
        padding: 20px;
      width: 90%;
      margin: auto;
      input{
        padding: 12px 13px ;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .divCombine{
        flex-direction: column;
        gap: 20px !important;
      }
      .namePhoneWrapper{
        flex-direction: column;
        gap: 25px;
        justify-content: space-between;
      }
      .description{
        font-size: 20px;
        padding: 12px 13px;
      }
    }
    }
  
}

}