.team-wrapper {
  //border: 2px solid pink;
  padding: 20px;
  padding-top: 50px;
  margin-top: 130px;
  position: relative;
  overflow: hidden !important;
//background-image: url("../../../../public/images/home/teamBg.png");
//background-repeat: no-repeat;

.teamGridLeft{
  position: absolute;
  top: -70px;
  left: -60px;
}
.teamGridRight{
  position: absolute;
  top: -240px;
  right: 0px;
}
  h2 {
    text-transform: uppercase;
    margin-bottom: 150px;
    span {
      &::after {
        top: -35px;
        right: -1px;
        width: 140.835px;
        height: 140.835px;
      }
    }
  }

  .membersContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-image: url("../../../../public/images/home/teamBg.png");
    background-repeat: no-repeat;
    background-position: center;
    flex-wrap: wrap;

}
}

@media screen and (max-width: 1700px){
  .teamGridLeft{
    display: none;
  }
  .teamGridRight{
    display: none;
  }

}

@media screen and (max-width: 1440px){
  .team-wrapper{
      h2{
          font-size: 40px;
          span{
        
              &::after{
                
                  width: 100px !important;
                  height: 100px !important;
                  top: -30px;
              }
          }
      }
  }

}
@media screen and (max-width:600px){
  .team-wrapper{
    margin-top: 50px;
  }
  .team-wrapper{ h2 {
    margin-bottom: 50px;
    span::after {

    //   top: -30px;
    // right: -10px;
    // width: 100.835px !important;
    // height: 100.835px !important;
}
}
  }


  .team-wrapper{
    h2{
       
        span{
      color: #2B2B2B;
            &::after{
              
               display: none;
            }
        }
    }
}
}
