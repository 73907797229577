h2 {
  color: #2b2b2b;
  font-family: Arial;
  font-size: 61px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: left;
  //overflow: hidden;
  
.leftSpan_circle {
    &::after {
      width: 162.207px !important;
      height: 162.207px;
      background-image:linear-gradient(90deg, #00C6FF 0%, #0072FF 100%);
      top: -50px;
      right: -7px;
    }
  }
}
@media screen and (max-width:1550px){

  h2{
    font-size: 45px;
    .leftSpan_circle{
      &::after{
        width: 130px !important;
        height: 130px !important;
        top: -40px;
      }
    }
  }
}

@media screen and (max-width:600px){
  h2{
    font-size: 35px;
    text-align: center;
    .leftSpan_circle{
      color: #2B2B2B;
      &::after{
        // width: 100px !important;
        // height: 100px !important;
        // top: -30px;
        display: none;
      }
    }
  }

}