.slickCard-wrapper {
  width: 839px;
  height: 395px;
  padding: 40px 20px 112px 10px;
  position: relative;
 //border: 1px solid black;
  display: flex;
  justify-content: start;
  align-items: start;
  background: none;
  box-shadow: none;
  margin-right: 20px !important;
  margin-top: 40px;
 // margin-bottom: 100px !important;
  transition: transform 0.3s linear;
  overflow: hidden;
  scroll-behavior: smooth;
  z-index: 666;
  &:hover {
    //transform: translateY(-50px) !important;
    //scroll-behavior: smooth;
   // transition: none;
    // transition: transform 0.3s linear;
    //padding-bottom: 200px !important;
    cursor: pointer;
    overflow: hidden;
    // animation: customAni 2s ease 1s infinite normal none;
  

    .card-part1 {
      //background-image: url("../../../../public/images/home/voiceBgChange.png");
      background: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
      color: white;
      // width: 225.04px;
      // height: 320px;
      //margin-left: 0;
       

      p {
        color: white;
      }

      .starsDiv {
        filter: brightness(300);
      }
    }
  }

  .card-part1 {
    //padding: 0;
    position: relative;
    width: 245.04px;
    height: 350px;
    //border: 1px solid gray;
    background-image: url("../../../../public/images/home/sliderpart1.png");
    background-repeat: no-repeat;
    background-size: 110% 100%;
    background-position: bottom;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 10px;
    border-radius: 8px;
    -webkit-box-shadow: -4px 4px 13px 0px rgba(0, 0, 0, 0.71);
    -moz-box-shadow: -4px 4px 13px 0px rgba(0, 0, 0, 0.71);
    box-shadow: -4px 4px 13px 0px rgba(0, 0, 0, 0.71);
    padding-bottom: 30px;
    //background: white;

    // &:hover{
    //   background-image: url("../../../../public/images/home/voiceBgChange.png");
    //   background-repeat: no-repeat;
    //   background-position: center;
    // }

    p {
      color: gray;
    }
    .starsDiv {
      width: 155.124px;
      height: 34.886px;
      background-image: url("../../../../public/images/home/stars.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .card-part2 {
    position: absolute;
    //border: 1px solid green;
    top: 150px;
    right: 0;
    height: 240px;
    width: 640px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
    img {
      width: 191.368px;
      height: 181.799px;
      object-fit: cover;
    }
    p {
      width: 449.16px;
      text-align: left;
      color: #707070;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Inter;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 136.364% */
      text-transform:unset;
    }
  }
}

@media screen and (max-width: 1879px) {
  .slickCard-wrapper {
    width: 746px;
    height: 370px !important;
    padding: 20px 20px 8px 10px;
    position: relative;
    .card-part1 {
      height: 314px;
      h5 {
        font-size: 25px;
      }
    }
    .card-part2 {
      top: 84px;
      right: 10px;
      height: 239px;
      width: 551px;
      justify-content: center;
      gap: 15px;
      //border: 1px solid green;

      p {
        width: 420.16px;
        font-size: 16px;
        line-height: 30px;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 1680px) {
  .slickCard-wrapper {
    margin-bottom: 20px !important;
    width: 620px;
  
    
    .card-part1 {
      width: 195.04px;
      height: 278px;
      h5 {
        font-size: 23px;
      }
      p {
        font-size: 20px;
      }
    }
    .card-part2 {
      top: 76px;
      right: -13px;
      height: auto;
      width: 470px;

      p {
        width: 268px !important;
        font-size: 15px;
      }
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
}
@media screen and (max-width: 1500px){

} 
@media screen and (max-width: 1310px) {
  .slickCard-wrapper {
    width: 53%;
    margin: 0 auto !important;
    margin-top: 60px !important;
  
    .card-part1 {
      width: 170px;
      &:hover{
        background-size: 140% 130%;
        background-position: center;
      }
      h5 {
        font-size: 21px;
      }
      p {
        font-size: 17px;
      }
    }
    .card-part2 {
      top: 74px;
      right: 64px;
      height: auto;
      width: 411px;
      img {
        width: 120px;
        height: 120px;
      }
      p {
        width: 268px !important;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .slickCard-wrapper {
    margin: 0 auto;
    width: 750px;
    .card-part2 {
      right: 178px;
    }
  }
}

@media screen and (max-width: 600px) {
  .slickCard-wrapper {
    padding: 40px 20px 0px 10px !important;
    width: 100%;
    height: 602px !important;
    margin-left: 10px;
    padding-bottom: 20px !important;
   
    //border: 1px solid rgb(224, 176, 17);
transition: none !important;
    &:hover{
      transform: none !important;
      transition: none !important;
    }
    .card-part1 {
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &:hover{
        background-size: 140% 130%;
        background-position: center;
      }
    }
    .card-part2 {
      gap: 0;
      right: 0 !important;
      width: 100%;
      top: 384px !important;
      p {
        width: 100% !important;
        padding: 10px;
        padding-bottom: 20px !important;
      }

      img {
        display: none;
      }
    }
  }
}
