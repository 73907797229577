.footer-container {
  width: 100%;
  height: auto;
  //border: 1px solid green;
  background-color: white;
  position: relative;

  .footerGrid {
    position: absolute;
    top: 0;
    right: 0;
  }
  .footerWrapper {
    width: 100%;
    height: auto;
    margin-top: 80px;
    padding-top: 30px;
    border-top:dashed #018eff;
    border-width: 3px !important;
    border-spacing: 3px !important;
    display: flex;
    align-items: start;
    justify-content: space-around;
  
   

    .footer-col-1 {
      width: 500px;
      height: auto;
      //border: 2px solid red;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .footerLogo {
        width: 133px;
        height: 150px;
        object-fit: cover;
        margin-bottom: 20px;
      }

      .footer-para {
        max-width: 523.478px;
        color: #5b5b5b;
        font-family: Inter;
        font-size: 19.97px;
        font-style: normal;
        font-weight: 400;
        line-height: 147.011%; /* 33.353px */
        letter-spacing: 0.799px;
        text-align: left;
        //margin-top: 45px;
      }

      .social-media-wrapper {
        width: 300px;
        height: 40px;
        //   border: 2px solid yellow;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;

        .iconStyle {
          width: 55px;
          height: 51px;
          color: #5b5b5b;

          &:hover {
            color: #018eff;
            cursor: pointer;
          }
        }
      }
    }
    .footer-col-2 {
      position: relative;
      z-index: 2222;
      padding-top: 30px;
      width: 200px;
      height: 300px;
      //border: 2px solid red;
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
      }

      h5 {
        color: var(--black);
        font-family: Poppins;
        font-size: 28.529px;
        font-style: normal;
        font-weight: 500;
      }
      a {
        color: #6a6a6a;
        font-family: Inter;
        font-size: 22.823px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 25px;
        &:hover {
          color: #018eff;
          cursor: pointer;
        }
      }
    }

    .footer-col-3 {
      position: relative;
      z-index: 2222;
      padding-top: 30px;
      width: 200px;
      height: 300px;
      //border: 2px solid red;
      display: flex;
      flex-direction: column;
      a {
        text-decoration: none;
      }
      h5 {
        color: var(--black);
        font-family: Poppins;
        font-size: 28.529px;
        font-style: normal;
        font-weight: 500;
      }
      a {
        color: #6a6a6a;
        font-family: Inter;
        font-size: 22.823px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 25px;
        &:hover {
          color: #018eff;
          cursor: pointer;
        }
      }
    }
    // .footer-col-4 {
    //   padding-top: 30px;
    //   width: 75px;
    //   height: fit-content;
    //   //border: 2px solid red;
    //   display: flex;

    //   .footer-arrow-up {
    //     display: flex;
    //     width: 60.602px;
    //     height: 60.602px;
    //     padding: 9.984px 9.986px 9.986px 9.984px;
    //     justify-content: center;
    //     align-items: center;
    //     flex-shrink: 0;
    //     border-radius: 4.279px;
    //     background: #018eff;
    //     position: fixed;
    //     bottom: 50px;
    //     right: 44px;
    //     z-index: 999;
    //     cursor: pointer;
    //   }
    // }
  }

  hr {
    width: 100%;
    height: 0.285px;
    background-color: var(--lightgray);
    margin-top: 50px;
  }

  .allRights {
    color: var(--gray);
    font-family: Inter;
    font-size: 19.97px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .footer-container {
    padding: 20px;
    //background: red;
    .footerWrapper {
      .footer-col-1 {
        width: 395px !important;
        .footer-para {
          max-width: 370px;
          width: 100%;
          margin-top: 40px;
        }
        .social-media-wrapper {
          margin-top: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .footer-container {
    .footerGrid {
      display: none;
    }
    .footerWrapper {
      //background: red;
      justify-content: center;
      flex-direction: column;
      gap: 30px;
      margin-top: 0;
      .footer-col-1 {
        width: 300px !important;
        .footerLogo {
          // width: 129.788px;
          // height: 143.117px;

          width: 100.788px;
          height: 109.117px;
        }
        .footer-para {
          max-width: 300px;
          font-size: 18px;
          line-height: normal;
          margin-top: 0;
        }
        .social-media-wrapper {
          justify-content: space-between;
          width: 260px;
          .iconStyle {
            width: 40px;
            height: 40px;
          }
        }
      }
      .footer-col-2 {
        height: auto;
        padding-top: 0;
        h5 {
          font-size: 24px;
        }
        a {
          margin-top: 10px;
          font-size: 18px;
        }
      }
      .footer-col-3 {
        height: auto;
        padding-top: 0;
        h5 {
          font-size: 25px;
        }
        a {
          margin-top: 10px;
          font-size: 18px;
        }
      }
      .footer-col-4 {
        padding-top: 0;
        .footer-arrow-up {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
