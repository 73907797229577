.workWrapper {
  padding: 20px;
  //border: 2px solid red;
  padding-bottom: 120px;
  margin-top: 80px;
  padding-top: 70px;
  position: relative;
  overflow: hidden;

  .workContent {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    //border: 1px solid green;
    margin-top: 120px;
    position: relative;

    .dottedLine {
      position: absolute;
      // border: 1px solid green;
      top: 46%;
      width: 0;
      left: 0%;
      transform: translate(0%, -50%);
      z-index: -1;
      animation: moveDottedLine 5s ease-out 2s forwards;
    }
    @keyframes moveDottedLine {
      0% {
        width: 20%;
        transition-delay: 0s;
      }
      25% {
        width: 40%;
        transition-delay: 4s;
      }
      50% {
        width: 60%;
        transition-delay: 6s;
      }
      75% {
        width: 80%;
        transition-delay: 8s;
      }
      100% {
        width: 100%;
        transition-delay: 10s;
      }
    }

    .workContentChild {
      display: flex;
      align-items: center;
      flex-direction: column;
      //border: 1px solid black;
      position: relative;
      .circleWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .circleContainer {
          display: flex;
          width: 348px;
          height: 349px;
          padding: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 5px dotted var(--darkBlue);
          background-color: white;
          animation: rotateCircleContainer 11s linear 7s infinite normal;
          // position: relative !important;

          @keyframes rotateCircleContainer {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(-360deg);
            }
          }

          // .imgContainer {
          //   width: 270px;
          //   height: 270px;

          // }
        }
        img {
          width: 270px;
          height: 270px;
          object-fit: cover;
          position: absolute;
          // top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%) !important;
        }
      }
      h4 {
        margin-top: 50px;
      }
    }
  }
}

@media screen and (max-width: 1560px) {
  .workWrapper {
    .workContent {
      flex-wrap: wrap;
      .dottedLine {
        display: none;
      }
      .workContentChild {
        padding: 20px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .workWrapper {
    margin: 0 auto;
    padding-bottom: 0;
    h2 {
      margin-top: 30px !important;
      margin-bottom: 50px;
    }
    .glowLeft {
      display: none;
    }
    .glowRight {
      display: none;
    }
    .workContent {
      margin-top: 20px;

      .workContentChild {
        width: 100%;

        .circleContainer {
          display: flex;
          width: 270px;
          height: 270px;
          img {
            width: 235px;
            height: 235px;
          }
        }
      }
    }
  }


  .workWrapper {
    padding-top: 0;
    .workContent {
    .workContentChild {
    .circleWrapper {
    .circleContainer {
    display: flex;
    width: 300px;
    height: 300px;
  }
  img{
    width: 250px;
    height: 250px;
  }
}
  }
}
  }
   
}
