.aboutBaner{
    //border: 1px solid red;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url("../../../../public/images/about/bannerImg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: black;
    overflow: hidden;
}

@media screen and (max-width:600px) {
    .aboutBaner{
        height: 400px;
   
    }
    
    
}