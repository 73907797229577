* {
}
html {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
  //overflow: hidden;
}
.blue-button {
  display: inline-flex;
  padding: 16px 52px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: none;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 6px;
  border: 2.43px solid var(--Button, #00c6ff);
}
.gradient-blue-button {
  display: inline-flex;
  padding: 16px 52px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: linear-gradient(90deg, #0072ff 0%, #00c6ff 50%, #0072ff 100%);
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 0 auto !important;
  border: none;

  &:hover {
    border-radius: 6px;
    background: linear-gradient(90deg, #01c6ff 0%, #0172ff 50%, #01c6ff 100%);
    cursor: pointer;
  }
}
.h-center {
  text-align: center !important;
}
.mx-auto {
  display: flex;
  justify-content: center !important;
  align-items: center;
}
.gray-layer {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.head-medium {
}
.glowLeft {
  position: absolute;
  top: 0;
  left: 0;
}
.glowRight {
  position: absolute;
  top: 0;
  right: 0;
}

.contact-label {
  color: #344054;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.contact-input {
  width: 100%;
  display: flex;
  padding: 12px 16px;
  outline: none;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  margin-bottom: 25px;

  &::placeholder {
    color: #667085;
  }
}

.topscroll {
  position: fixed;
  bottom: 50px !important;
  right: 50px !important;
  z-index: 9999;
  // position: absolute;
  cursor: pointer;
  border-radius: 10px;
}
@media screen and (max-width: 600px) {
  .gradient-blue-button {
    padding: 16px 22px !important;
  }
  .glowLeft {
    display: none;
  }
  .glowRight {
    display: none;
  }
}
