.personConainer {
  // width: 502.06px;
  // height: 753.279px;
  width: 434.06px;
  height: 682.279px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .employeeImg {
    width: 410px;
    height: 400px;
    border-radius: 50%;
    background-image: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
    img {
      position: absolute;
      bottom: -10px;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
    }

    //border: 1px solid red;
  }
  p {
    color: #2b2b2b;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
@media screen and (max-width: 1440px) {

.personConainer{
  .employeeImg{
    width: 370px;
    height: 370px;
    img{
      width: 380px;
      
    }
  }
}
}
@media screen and (max-width: 600px) {
  .personConainer {
    height: 540.279px;
    width: 300px;
    .employeeImg {
      width: 300px;
      height: 300px;

      img {
        width: 300px;
        height: 400px;
      }
    }
  }
}
