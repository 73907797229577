.privacypolicy{
    width: 85%;
    margin: auto;
    margin-top: 250px;

    .privacytop{
            h1{
                color: #2B2B2B;
                font-family: "Poppins";
                font-size: 61px;
                font-style: normal;
                font-weight: 600;
                text-align: center;
            }
        
        h2{
            color: #2B2B2B;
            font-family: "Inter";
            font-size: 61px;
            font-weight: 600;
            text-align: start;
            margin-top: 80px;
        }
        p{
            color: #707070;
            font-family: "Inter";
            font-size: 22px;
            font-weight: 400;
            text-align: start;
            margin-top: 40px;
        }
    }

    .privacycontent{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        
        h2{
            color: #2B2B2B;
        font-family: "Inter";
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        margin-top: 40px;

       
        }
        p{
            color: #707070;
            font-family: "Inter";
            font-size: 22px;
            font-weight: 400;
            padding-top: 18px;
            text-align: unset;
        }
    }
   .privacybottom{
    margin-top: 63px;
    p{
        color: #707070;
        font-family: "Inter";
        font-size: 22px;
        font-weight: 400;
    }
   }
}

@media screen and (max-width:600px) {
    .privacypolicy{
        width: 90%;
        margin-top: 50px;

        .privacytop{
            h1{
                font-size: 27px !important ;
                text-align: start !important;
            }
            h2{
                font-size: 30px !important;
                margin-top: 25px ;
            }
            h4{
                font-size: 22px;
            }
            p{
                font-size: 15px !important;
                margin-top: 20px;
                line-height: normal !important;
            }
        }
        .privacycontent{
            h2{
                font-size: 20px !important;
                margin-top: 15px;
                text-align: start;
            }
            p{
                font-size: 15px !important;
                padding-top: 5px;
                line-height: normal;
            }
        }
        .privacybottom{
            margin-top: 20px;
            p{
                font-size: 15px;
                line-height: normal;
            }
        }
    }
    
}

