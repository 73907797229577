.projectsWrapper {
  padding: 60px 20px !important;
  // border: 1px solid red;
  position: relative;

  .ourProjectContainer {
    padding: 10px;
    //border: 2px solid green;
    display: flex;
    align-items: center;
    row-gap: 50px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 100px;
    position: relative;

    .btmGlowLeft {
      width: 435.368px;
      height: 707.43px;
      position: absolute;
      bottom: -140px;
      left: 0;
    }

    .btmGlowRight {
      width: 435.368px;
      height: 707.43px;
      position: absolute;
      bottom: -140px;
      right: 0;
    }
  }
}

@media screen and (max-width:600px){
  .projectsWrapper{
    padding-top: 20px !important;
    h2{
      font-size: 30px !important;
    }
    .ourProjectContainer{
      margin-top: 20px;
      row-gap:65px;
      .btmGlowLeft{
        display: none;
      }
      .btmGlowRight{
        display: none;
      }
    }
  }
  
}