.brand-wrapper {
  padding: 20px;
 // border: 1px solid red;
  margin-top: 80px !important;
  padding-top: 50px;
  text-align: center;
  padding-bottom: 100px;
  overflow: hidden;
  

  h2 {
    text-align: center;
    text-transform: uppercase;

    span {
      &::after {
        top: -35px;
        right: 8px;
        width: 150px;
        height: 150px;
      }
    }
  }

  p {
    max-width: 1059.89px;
    color: #707070;
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0 auto;
    margin-top: 60px;
    text-transform: none;
  }

  .brand-logo-Container {
    width: 87%;
    //border: 1px solid green;
    padding: 20px;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 30px;
    //flex-wrap: wrap;


    .appleLogo {
      height: 50px;
    }
  }
//   .marquee {
//     overflow: hidden;
//   }
  
//   .marquee div {
//     display: inline-block;
//     animation: marquee 3s linear infinite;
//   }
  
// @keyframes marquee {
//   0%   { transform: translateX(0); }
//   100% { transform: translateX(100%); }
// }
}

@media screen and (max-width: 1400px) {
  .brand-wrapper .brand-logo-Container {
    width: 100%;
    //flex-wrap: wrap;
    justify-content: center;
  
}
  .brand-wrapper{
      h2{
          font-size: 40px;
          span{
              &::after{
                  width: 120px !important;
                  height: 120px !important;
                  left: -10px;
                  top: -37px;
              }
          }
      }
  }


}
@media screen and (max-width: 600px){
//   .brand-wrapper h2 span::after {
//       width: 98px !important;
//       height: 98px !important;
//       left: -10px;
//       top: -30px;
  
// }

.brand-wrapper .brand-logo-Container {
  gap: 60px;
  align-items: center;
  justify-content: center;
 
}
.brand-wrapper{
  p{
    font-size: 18px !important;
  }
.brand-logo-Container{
  flex-direction: row;
}
}

.brand-wrapper {
  h2 {
    span {
      color: #2B2B2B;
      &:after {
        display: none;
      }
    }
  }
}
}