.navbar-Wrapper {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: auto;
  color: var(--white);
  z-index: 999;

  .navContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .nav-logo {
      width: 220px;
      padding: 5px;
      margin-top: 10px;

      // .logoMeta{
      //   width: 300px;
      //   height: 120px;
    
      // }
    }

     &.black-background {
      background-color: black; // Apply black background when privacy or terms route is active
    }

    .navbar-nav {
      color: var(--white) !important;

      ul {
        text-decoration: none;
        list-style: none;
        display: flex;
        gap: 65px;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 0;

        li {
          color: white !important;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;

        

          a {
            color: white ;
            text-decoration: none;
        
            &:hover {
              color: var(--blue) !important;
              cursor: pointer;
            }
            

            
           
          }
          
        }
      }
    }

    .nav-contact {
      width: 220px;
      //margin-right: 70px;

      .blue-button {
        &:hover {
          border-radius: 6px;
          background: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
          cursor: pointer;
        }
      }
    }
  }

  &.privacy-terms-active {
    li {
      color: black !important;
    }
  }
}





@media screen and (max-width: 2500px) {
  .mobile-navbar {
    display: none;
  }
}
@media screen and (max-width: 1465px) {
  .navbar-Wrapper {
    .navContent {
      margin-right: 50px;
      .navbar-nav {
        ul {
          gap: 55px;
        }
      }
    }
  }
}
.blue-button {
  padding: 16px 38px;
}
@media screen and (max-width: 1220px) {
  .navbar-Wrapper {
    .navContent {
      justify-content: space-around;
      .nav-logo {
        img {
          margin-left: 10px;
          width: 180px !important;
        }
      }
      .navbar-nav {
        ul {
          gap: 40px;
        }
      }
      .nav-contact {
        width: 180px;
        margin-right: 0;
      }
    }
  }
  .navbar-Wrapper .navContent .nav-logo img {
  width: 200px;
}
  .blue-button {
    padding: 16px 25px !important;
    font-size: 17px !important;
  }
}

@media screen and (max-width: 610px) {
  .navbar-Wrapper {
    display: none;
  }

  .mobile-navbar {
    width: 100%;
    height: 68px;
    background:black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:  12px;

    img{
      width: 45px;
      height: 45px;
    }

    .menu-btn {
      background: transparent;
      border: none;
      width: 56px;
      height: 39px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        width: 32px;
        height: 32px;
      }
    }
  }
  .offcanvas{
    width: 70% !important;
  }
  .offcanvas-header {
    background: #000;
  }
  .offcanvas-body {
    background: #000;
    color: var(--white);

    
  }
  .Offcanvas.Title{
    img{
      width: 30px;
      height: 30px;
    }
  }
  .blue-button{
    border: 2.3px solid #fff !important;

    &:hover{
      background-image:  linear-gradient(90deg, #00C6FF 0%, #0072FF 100%);
      border: none !important;
    }

  }
  .logostyle {
   width: 45px;
   height: 45px;
  }
  li {
    margin-bottom: 20px;
    font-size: 22px;
    color: var(--white);
    a {
      color: var(--white);
      text-decoration: none;
    }
    
  }
}
.offcanvas-header .btn-close {
  display: none;

}